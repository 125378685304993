<template>
  <div>
    <v-container grid-list-xl>
      <v-layout fluid pt-0 wrap>
        <v-flex xs12>
          <app-card
            :footer="true"
            heading="Customer creation"
            col-classes="xl12 lg12 md12 sm12 xs12"
            custom-classes="mb-0"
          >
            <div>
              <v-container grid-list-xl>
                <v-layout row wrap>
                  <v-flex xs12>
                    <users-input
                      v-model="passengerData.user"
                      @changeUser="
                        (inputUser) => {
                          passengerData.user = inputUser.id
                          userData = { ...inputUser, phone: inputUser.phone && inputUser.phone.number }
                        }
                      "
                    />
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      v-model="userData.firstName"
                      label="First name"
                    />
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      v-model="userData.lastName"
                      label="Last name"
                    />
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field v-model="userData.email" label="Email" />
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field v-model="userData.phone" label="Phone" />
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field v-model="userData.country" label="Country" />
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      v-model="userData.language"
                      label="Language"
                    />
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-select
                      v-if="brandList.length"
                      :items="brandList"
                      :rules="[
                        (v) => v.length > 0 || 'Please select at least 1 brand',
                      ]"
                      v-model="userData.brands"
                      :item-text="
                        (val) => {
                          return val.name
                        }
                      "
                      clearable
                      item-value="id"
                      multiple
                      label="Brands"
                    />
                  </v-flex>
                  <v-flex xs12 sm6>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-switch
                      v-model="passengerData.business"
                      label="Business"
                    />
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field
                      v-model="passengerData.companyName"
                      label="Company name"
                    />
                  </v-flex>
                  <v-flex xs6>
                    <div>
                      <span>Invoice address</span>
                      <v-radio-group
                        v-model="passengerData.invoiceType"
                        :class="'custom-radio-inline'"
                        row
                        hide-details
                      >
                        <v-radio label="Private" value="private" />
                        <v-radio label="Business" value="business" />
                      </v-radio-group>
                    </div>
                  </v-flex>
                  <v-flex xs6>
                    <v-switch
                      v-model="passengerData.useInvoiceEmailAsDefault"
                      label="Only use invoice email"
                    />
                  </v-flex>
                  <v-flex xs6>
                    <v-select
                      :items="invoicingTypeOptions"
                      v-model="passengerData.invoicingType"
                      item-value="value"
                      item-text="text"
                      label="Invoice period"
                      filled
                      hide-details
                    />
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      v-model="passengerData.invoiceEmail"
                      label="Invoice email"
                    />
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field
                      v-model="passengerData.secondEmail"
                      label="Second email"
                    />
                  </v-flex>
                  <v-flex xs12 sm6>
                    <InputHotKeyWrapper>
                      <v-text-field
                        v-model="passengerData.travelKmTariff"
                        label="Travel km tariff"
                        type="number"
                      />
                    </InputHotKeyWrapper>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-autocomplete
                      v-model="passengerData.defaultDriverType"
                      :items="getDriverTypesEdrivers()"
                      :item-text="
                        (val) => {
                          return val.title + ' id: ' + val.id
                        }
                      "
                      label="Default driver type (ED)"
                      item-value="id"
                      hide-details
                      clearable
                    />
                  </v-flex>
                  <v-flex xs12 sm6>
                    <address-input
                      :address="passengerData.address"
                      label="Address"
                      @onChange="
                        (val) => {
                          passengerData.address = val
                        }
                      "
                    />
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-textarea
                      v-model="passengerData.description"
                      label="Description"
                    />
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-textarea
                      v-model="passengerData.adminNote"
                      label="Admin note"
                    />
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field
                      v-model="passengerData.poNumber"
                      label="PO number"
                    />
                  </v-flex>
                  <v-flex xs12 sm6>
                    <group-switch
                      :value="passengerData.insure"
                      :default-value="insuranceOptionValues.ask"
                      :option-values="insuranceOptionValues"
                      label="Insure"
                      @onChange="onInsuranceChange"
                    />
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-select
                      :items="carPositionList"
                      v-model="passengerData.options.position"
                      label="Position in car"
                    />
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-select
                      :items="driverDressList"
                      v-model="passengerData.options.driverDress"
                      label="Driver dress"
                    />
                  </v-flex>
                  <v-flex xs12 sm6>
                    <group-switch
                      :value="passengerData.options.carryLuggage"
                      label="Carry luggage"
                      @onChange="onCarryLuggageChange"
                    />
                  </v-flex>
                  <v-flex xs12 sm6>
                    <group-switch
                      :value="passengerData.options.informalLanguage"
                      label="Informal language"
                      @onChange="onInformalLanguageChange"
                    />
                  </v-flex>
                  <v-flex xs12 sm6>
                    <group-switch
                      :value="passengerData.options.keepDoorsOpen"
                      label="Keep doors open"
                      @onChange="onKeepDoorsOpenChange"
                    />
                  </v-flex>
                  <v-flex xs12 sm6>
                    <group-switch
                      :value="passengerData.options.playMusic"
                      label="Play music"
                      @onChange="onPlayMusicChange"
                    />
                  </v-flex>

                  <v-flex xs6>
                    <v-switch
                      v-model="passengerData.payOnInvoice"
                      label="Pay on invoice"
                    />
                  </v-flex>
                </v-layout>
              </v-container>
            </div>
            <template slot="footer">
              <v-btn color="primary" class="mr-3" @click="saveFunction()"
                >Create</v-btn
              >
            </template>
          </app-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import Request from '../../../../helpers/request'
import UsersInput from '../../../../modules/components/UsersInput.vue'
import AddressInput from '../../../../modules/components/AddressInput.vue'
import GroupSwitch from 'Components/GroupSwitch'
import Brands from '../../../../helpers/brands'
import Roles from '../../../../helpers/roles'
import {
  BRAND,
  PASSENGER_CAR_POSITIONS,
  DRIVER_DRESSES,
  INSURE_PASSENGER_ASK,
  INSURE_PASSENGER_ALWAYS,
  INSURE_PASSENGER_NEVER,
} from '../../../../constants/common'

export default {
  mixins: [Request, Brands, Roles],
  components: { UsersInput, AddressInput, GroupSwitch },
  data() {
    return {
      brandList: [],
      driverTypes: [],
      passengerData: {
        options: {},
        brands: [],
        user: null
      },
      userData: {},
      invoicingTypeOptions: [
        { text: 'each ride', value: 'per_ride' },
        { text: 'weekly', value: 'weekly' },
        { text: 'monthly', value: 'monthly' },
      ],
      showPasswordField: false,
      roleList: [],
      insuranceOptionValues: {
        on: { text: 'ON', value: INSURE_PASSENGER_ALWAYS },
        middle: { text: 'ASK', value: INSURE_PASSENGER_ASK },
        off: { text: 'OFF', value: INSURE_PASSENGER_NEVER },
      },
      carPositionList: PASSENGER_CAR_POSITIONS,
      driverDressList: DRIVER_DRESSES,
    }
  },
  async beforeMount() {
    this.brandList = await this.fetchBrands()
    this.roleList = await this.fetchRoles()
    this.driverTypes = await this.fetchDriverTypes()
  },
  methods: {
    getDriverTypesEdrivers() {
      if (this.driverTypes && this.driverTypes.length) {
        return this.driverTypes.filter(
          (item) => item.brand.name === BRAND.EDRIVERS
        )
      }
    },
    fetchDriverTypes() {
      return new Promise((resolve, reject) => {
        this.request(
          'GET',
          '/driver-types',
          {},
          ({ data }) => {
            resolve(data.data)
          },
          null,
          reject
        )
      })
    },
    onCarryLuggageChange(value) {
      this.passengerData.options.carryLuggage = value
    },
    onInformalLanguageChange(value) {
      this.passengerData.options.informalLanguage = value
    },
    onKeepDoorsOpenChange(value) {
      this.passengerData.options.keepDoorsOpen = value
    },
    onPlayMusicChange(value) {
      this.passengerData.options.playMusic = value
    },
    onInsuranceChange(value) {
      this.passengerData.insure = value
    },
    async saveFunction() {
      let bodyUser = {
        data: {
          email: this.userData.email,
          name: this.userData.firstName + ' ' + this.userData.lastName,
          phone: this.userData.phone,
          country: this.userData.country,
          language: this.userData.language,
          brands: this.userData.brands.map((brand) => brand.id || brand),
        },
      }

      let body = {
        data: {
          user: this.passengerData.user,
          description: this.passengerData.description,
          address: this.passengerData.address.address,
          insure: this.passengerData.insure,
          brands: this.userData.brands.map((brand) => brand.id || brand),
          options: this.passengerData.options,
          payOnInvoice: this.passengerData.payOnInvoice,
          adminNote: this.passengerData.adminNote,
          business: this.passengerData.business,
          companyName: this.passengerData.companyName,
          travelKmTariff: this.passengerData.travelKmTariff,
          defaultDriverType: this.passengerData.defaultDriverType || null,
          secondEmail: this.passengerData.secondEmail,
          invoicingType: this.passengerData.invoicingType,
          invoiceEmail: this.passengerData.invoiceEmail,
          useInvoiceEmailAsDefault: this.passengerData.useInvoiceEmailAsDefault,
          poNumber: this.passengerData.poNumber,
          invoiceType: this.passengerData.invoiceType,
        },
      }

      let user = null

      if (!this.passengerData.user) {
        bodyUser.data = {
          ...bodyUser.data,
          roles: 'ROLE_PASSENGER',
          plainPassword: this.gFunc.generatePassword(32),
        }
        const { data } = await this.request('POST', '/users', bodyUser)
        user = data
        body.data.user = user.id
      }

      this.request('POST', `/passengers`, body, async ({ data }) => {
        if (this.passengerData.user) {
          await this.request(
            'PATCH',
            `/users/${this.passengerData.user}`,
            bodyUser
          )
        }

        this.showPasswordField = false

        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Passagier is aangemaakt',
        })

        return this.$router.push(`/tables/passengers/${data.id}`)
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
